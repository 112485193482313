import React from "react"
import {injectIntl} from "gatsby-plugin-intl"
import Button from "../common/button";
//import {navigate} from "@reach/router";
//import {checkLanguage, paths} from "../../utils";
import SVGPhone from "../../images/svgs/llamar.svg";

const MoreInfoButton = ({children,location,intl,type}) => {
    return (
        <Button id="gta_click_call"
                onClick={()=>{
                    window.dataLayer.push({'event': 'buyClickCall'});
                    window.open('tel:900900916');
                    }}
                rounded
                btnType={type}
                fullwidth={true}
        >
            {children}
            <SVGPhone/>
        </Button>
    )
}

export default injectIntl(MoreInfoButton)
